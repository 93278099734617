import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  isHidden: boolean;

  constructor() {
    this.isHidden = window.innerWidth <= 550;

  }

  ngOnInit() {
    this.isHidden = window.innerWidth <= 550;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isHidden = window.innerWidth <= 550;

  }
}
