import { FooterComponent } from './layout/footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './layout/header/header.component';



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule, 
    RouterLink
  
  ],
  exports: [
    MaterialModule,
    FooterComponent,
    HeaderComponent

  ]
})
export class SharedModule { }
