<div class="footer">
    <div class="line-footer">
      <div *ngIf="!isHidden">
        <p>© 2024 Impulsamostuclinica.com Todos los derechos reservados</p>
      </div>
      <div *ngIf="isHidden">
        <p>© 2024 Impulsamostuclinica.com</p>
        <p>Todos los derechos reservados</p>
      </div>
        <div class="privacy">
            <p class="links pointer" routerLink="/politica-privacidad">Politica de privacidad</p>
            <p>-</p>
            <p class="links pointer" routerLink="/aviso-legal">Aviso Legal</p>
        </div>
    </div>
</div>
